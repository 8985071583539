import React, { useState, useEffect } from 'react';
import 'assets/styles/pages/sidebar.scss';
import { Link, navigate } from 'gatsby';
import childrenadd from 'assets/images/admin/children-addimage.png';
import treeicon from 'assets/images/admin/tree-icon.png';
import breadcumbarrow from 'assets/images/admin/breadcumb-arrow-left.png';
import { useForm } from "react-hook-form";

import AdminService from 'services/admin/admin.service';
import { toast, ToastContainer } from 'react-toastify';
import { Modal, Spinner } from 'react-bootstrap';
import PlantationListDetails from 'services/plantation/plantation.service';

import closesmall from 'assets/images/icons/close-small-icon.svg';

import imageCompression from 'browser-image-compression';

import { compressOptions } from 'assets/data/imageCompress';
import { doAdminLogout } from './login';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import config from "assets/config";

export default function Specieseditpage(props) {
  const adminApi = new AdminService();
  const ds = new PlantationListDetails();
  const [speciesEditData, setSpeciesEditData] = useState([]);
  const [loading, setLoading] = useState([false]);
  const [pathImage, setPathImage] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [signedList, setSignedList] = useState([]);
  const [fileError, setFileError] = useState([false]);
  const [allImage, setAllImage] = useState([]);
  const [commonName, setCommonName] = useState([]);
  const [naturalHabitat, setNaturalHabitat] = useState([]);
  const [speciesSlugName, setSpeciesSlugName] = useState([]);
  const [commonUses, setCommonUses] = useState([]);
  const [scientificName, setScientificName] = useState([]);
  const [speciesName, setSpeciesName] = useState([]);
  const [statusType, setStatusType] = useState("");
  const [filesDataList, setFilesDataList] = useState([]);
  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const EditValues = props.data;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const statusChange = (e) => {
    setStatusType(e.target.value);
  };
  const saveImg = async () => {
    setLoading(true);
    const plantationResponse = await ds.imagesSignedUrl({
      signedUrlDetails: signedList,
    });
    if (plantationResponse !== undefined) {
      if (plantationResponse.status === 200) {
        //  setImageLoadedList(plantationResponse.data.signedUrlDetails);
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong");
    }
    await imageUploadCall(plantationResponse.data.signedUrlDetails);
  };

  const imageUploadCall = async (data) => {
    setLoading(true);
    let pathImages = [];
    const resultsData = await data.map((item, index) => {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/octet-stream");
      myHeaders.append("x-goog-acl", "public-read");

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: filesList[index],
        redirect: "follow",
      };
      setLoading(true);
      return fetch(item.signedUrl, requestOptions).then((result) => {
        var obj = {};
        obj["pictureUrl"] = data[index].objectPath;
        pathImages.push(obj);
      });
    });
    return Promise.all(resultsData)
      .then(() => {
        finalCall(pathImages);
      })
      .catch((e) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  const finalCall = (images) => {
    setLoading(true);
    let formData = {
      speciesId: EditValues.id,
      speciesName: speciesName,
      status: statusType,
      scientificName: scientificName,
      commonName: commonName,
      naturalHabitat: naturalHabitat,
      speciesSlugName: speciesSlugName,
      commonUses: commonUses,
      pictureDetails: images,
    };
    const stringData = JSON.stringify(formData);
    adminApi
      .updateSpecies(stringData)
      .then((data) => {
        if (data.status === 200) {
          toast.success("Species Details Updated successfully");
          setTimeout(function () {
            setLoading(false);
            navigate("/admin/speciesstatus");
          }, 3000);
        } else {
          toast.error("Something went wrong");
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        pathImage.length = 0;
        setSignedList([]);
        if (error.response) {
          if (error.response.status === 401) {
            toast.error("JWT Token has Expired");
            navigate("/");
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };
  const onSubmit = async (data) => {
    if (
      speciesName !== "" &&
      statusType !== "" &&
      scientificName !== "" &&
      commonName !== "" &&
      naturalHabitat !== "" &&
      commonUses !== "" &&
      speciesSlugName !== ""
    ) {
      if (filesDataList.length === 0) {
        setLoading(true);
        let formData = {
          speciesId: EditValues.id,
          speciesName: speciesName,
          status: statusType,
          scientificName: scientificName,
          commonName: commonName,
          naturalHabitat: naturalHabitat,
          speciesSlugName: speciesSlugName,
          commonUses: commonUses,
          pictureDetails: [],
        };
        const stringData = JSON.stringify(formData);
        adminApi
          .updateSpecies(stringData)
          .then((data) => {
            if (data.status === 200) {
              toast.success("Species Details Updated successfully");
              setLoading(false);
              setTimeout(function () {
                navigate("/admin/speciesstatus");
              }, 3000);
            } else {
              toast.error("Something went wrong");
              setLoading(false);
            }
          })
          .catch((error) => {
            pathImage.length = 0;
            setSignedList([]);
            setLoading(false);
            toast.error(error.response.data.message);
          });
      } else {
        await saveImg();
      }
    }
  };
  const loadSpeciesData = async () => {
    setLoading(true);
    await adminApi
      .getSpecies(EditValues.id)
      .then((data) => {
        if (data.status === 200) {
          setSpeciesEditData(data.data);
          setAllImage(data.data.speciesImageUrls);
          setSpeciesName(data.data.speciesName);
          setScientificName(data.data.scientificName);
          setCommonName(data.data.commonName);
          setNaturalHabitat(data.data.naturalHabitat);
          setSpeciesSlugName(data.data.speciesSlugName);
          setCommonUses(data.data.commonUses);
          setStatusType(data.data.status === "ACTIVE" ? 2 : 1);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            doAdminLogout();
            toast.error("Authentication failed");
            navigate("/admin/");
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };

  const fileLoad = async (e) => {
    let testFile = e.target.files[0];
    const reader = new FileReader();
    let fileExtension = testFile.type.split("/").pop();
    const compressedFile = await imageCompression(testFile, compressOptions);
    if (
      fileExtension !== "png" &&
      fileExtension !== "jpg" &&
      fileExtension !== "jpeg" &&
      fileExtension !== "gif"
    ) {
      setTypeError(true);
      setFileError(false);
    } else if (compressedFile.size > 2e6) {
      setSizeError(true);
      setFileError(false);
      setTypeError(false);
    } else {
      setLoading(true);
      if (e.target.files) {
        const name = e.target.files[0].name;
        const lastDot = name.lastIndexOf(".");

        const fileName = name.substring(0, lastDot);
        const file = e.target.files[0];
        const filetype = e.target.files[0].type;
        let fileExtension = file.type.split("/").pop();
        let imgData = {
          fileName: fileName,
          fileExtension: fileExtension,
          fileType: filetype,
        };
        const filesData = [...filesList, file];
        const signedData = [...signedList, imgData];
        setFilesList(filesData);
        setSignedList(signedData);
        setFilesDataList([...filesDataList, e.target.files[0]]);
        //  setAllImage([...allImage, URL.createObjectURL(e.target.files[0])]);
        setFileError(false);
        setLoading(false);
      }
    }
  };

  const imageRemove = (e) => {
    let myArray;
    let infoArray;
    myArray = filesList.filter(function (obj) {
      return obj.name !== e.currentTarget.id;
    });
    setFilesList(myArray);
    infoArray = signedList.filter(function (obj) {
      const lastDot = e.currentTarget.id.lastIndexOf(".");
      const fileName = e.currentTarget.id.substring(0, lastDot);
      return obj.fileName !== fileName;
    });
    setSignedList(infoArray);
  };
  useEffect(() => {
    if (EditValues !== null) {
      loadSpeciesData();
    }
  }, []);
  return (
    <>
      <div className="admin-breadcumb-item">
        <span>
          <Link className="" to="/admin/dashboardview/">
            {" "}
            <img src={treeicon} alt="treeicon" />{" "}
          </Link>
        </span>
        <span>
          <img src={breadcumbarrow} alt="arrowicon" />
        </span>
        <h3> {speciesName} </h3>
        {/* <span>
          <img src={breadcumbarrow} alt='arrowicon' />
        </span>
        <Link className='' to='/admin/speciesstatus'>
          {' '}
          Back{' '}
        </Link> */}
      </div>

      <div className="white-iitiative-wrapper">
        <div className="">
          <h3 className="sub-heading-title"> EDIT SPECIES DETAILS </h3>
        </div>

        <div className="register-plantation">
          <div className="container">
            <div className="row">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="fc-wrapper">
                      <label htmlFor="name" className="form-label">
                        Species Name <i className="hastric-color">*</i>
                      </label>
                      <input
                        type="text"
                        name="speciesName"
                        className="form-control "
                        id="speciesName"
                        onChange={(e) => setSpeciesName(e.target.value)}
                        value={speciesName}
                      />
                      {speciesName === "" ? (
                        <span className="form-error">
                          Species Name is Required
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="fc-wrapper">
                      <label htmlFor="date" className="form-label">
                        Scientific Name <i className="hastric-color">*</i>
                      </label>
                      <input
                        type="text"
                        name="scientificName"
                        className="form-control "
                        id="scientificName"
                        value={scientificName}
                        onChange={(e) => {
                          setScientificName(e.target.value);
                        }}
                      />
                      {scientificName === "" ? (
                        <span className="form-error">
                          Scientific Name is Required
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="fc-wrapper">
                      <label htmlFor="date" className="form-label">
                        Common Names <i className="hastric-color">*</i>
                      </label>
                      <input
                        type="text"
                        name="commonName"
                        className="form-control "
                        id="commonName"
                        value={commonName}
                        onChange={(e) => {
                          setCommonName(e.target.value);
                        }}
                      />
                      {commonName === "" ? (
                        <span className="form-error">
                          Common Name is Required
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="fc-wrapper">
                      <label htmlFor="date" className="form-label">
                        Natural Habitat <i className="hastric-color">*</i>
                      </label>
                      <input
                        type="text"
                        name="naturalHabitat"
                        className="form-control "
                        id="naturalHabitat"
                        value={naturalHabitat}
                        onChange={(e) => {
                          setNaturalHabitat(e.target.value);
                        }}
                      />
                      {naturalHabitat === "" ? (
                        <span className="form-error">
                          Natural Habitat is Required
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="fc-wrapper">
                      <label htmlFor="name" className="form-label">
                        Common Uses <i className="hastric-color">*</i>
                      </label>
                      <textarea
                        type="textarea"
                        name="commonUses"
                        className="form-control "
                        id="commonUses"
                        value={commonUses}
                        onChange={(e) => {
                          setCommonUses(e.target.value);
                        }}
                      />
                      {commonUses === "" ? (
                        <span className="form-error">
                          Common Uses is Required
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fc-wrapper">
                      <label htmlFor="date" className="form-label">
                        Slug Name <i className="hastric-color">*</i>
                      </label>
                      <input
                        type="text"
                        name="speciesSlugName"
                        className="form-control "
                        id="speciesSlugName"
                        value={speciesSlugName}
                        onChange={(e) => {
                          setSpeciesSlugName(e.target.value);
                        }}
                      />
                      {speciesSlugName === "" ? (
                        <span className="form-error">
                          Species Slug Name is Required
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12 mg-top30">
                    <h3 className="sub-heading-title"> ADD IMAGES </h3>

                    <div className="dis-flex cent-item-mob  add-more-img-posi">
                      {/* {allImage && allImage.map(data => {
                        return (<img src={data} className='img-fluid' alt='Add image List' width="140px" height="70px" />)

                      })} */}

                      <SimpleReactLightbox>
                        <SRLWrapper>
                          {allImage &&
                            allImage.map((data) => {
                              return (
                                <div className="recent-plantation-section-plantation">
                                  <div
                                    className="img-wrapper img_wrp hand-pointer"
                                    key={data}
                                  >
                                    <img
                                      src={data}
                                      className="img-fluid"
                                      alt=""
                                      width="140px"
                                      height="100px"
                                    />
                                    <div className="overlay overLay-img">
                                      <i className="icon icon-arrows-fullscreen"></i>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </SRLWrapper>
                      </SimpleReactLightbox>

                      {filesList &&
                        filesList.map((data) => {
                          return (
                            <div className="img_wrp">
                              <span className="small-close small-close-images hand-pointer close">
                                <div id={data.name} onClick={imageRemove}>
                                  {" "}
                                  <img
                                    src={closesmall}
                                    className="img-fluid"
                                    alt="Close small"
                                  />{" "}
                                </div>
                              </span>
                              <img
                                src={URL.createObjectURL(data)}
                                className="img-fluid"
                                alt="Add image List"
                                width="140px"
                                height="70px"
                              />
                            </div>
                          );
                        })}

                      <div className="border-items margin-left-11">
                        <span className="plus-icon-green btn-file">
                          <i className="icon icon-plus-lg plus-symbol-custom-icon"></i>
                          <input
                            type="file"
                            onChange={fileLoad}
                            disabled={filesList.length === 5}
                            accept="image/png, image/gif, image/jpeg"
                          />
                          Add more
                        </span>
                      </div>
                    </div>
                    <div>
                      {sizeError === true ? (
                        <span className="form-error">
                          Please upload a file smaller than 2 MB
                        </span>
                      ) : null}
                      {typeError === true ? (
                        <span className="form-error">
                          File does not support. You must use .png or .jpg
                        </span>
                      ) : null}
                      {fileError === true ? (
                        <span className="form-error">
                          Add at least one image
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="fc-wrapper">
                      <label htmlFor="planttype" className="form-label">
                        Status <i className="hastric-color">*</i>
                      </label>
                      <select
                        name="planttype"
                        id="planttype"
                        className="form-control"
                        value={statusType}
                        onChange={(e) => {
                          statusChange(e);
                        }}
                      >
                        <option value="2">Active</option>
                        <option value="1"> Inactive </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <p className="mb-5 mt-2">
                    <b>QR Code URL </b>
                    <br />
                    {config?.websiteUrl + `species/?slug=${speciesSlugName}`}
                  </p>
                </div>
                <div className="text-right">
                  <Link
                    to="/admin/speciesstatus"
                    className="btn btn-cancel mg-right10 margin-btm-10"
                  >
                    Cancel
                  </Link>

                  <button className="btn btn-viewmore save-width">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
